export enum PagesEnum {
    TEAM_HATSTATS = "menu.best_teams",
    LEAGUE_UNITS = "menu.best_league_units",
    TEAM_OVERVIEW = "menu.team_overview",
    PLAYER_GOAL_GAMES = "menu.player_goal_games",
    PLAYER_CARDS = "menu.player_cards",
    PLAYER_SALARY_TSI = "menu.player_salary_tsi",
    PLAYER_RATINGS = "menu.player_ratings",
    PLAYER_INJURIES = "menu.player_injuries",
    TEAM_SALARY_TSI = "menu.team_salary_tsi",
    TEAM_CARDS = "menu.team_cards",
    TEAM_RATINGS = "menu.team_ratings",
    TEAM_AGE_INJURY = "menu.team_age_injury",
    TEAM_GOAL_POINTS = "menu.goals_points",
    TEAM_POWER_RATINGS = "table.power_rating",
    TEAM_FANCLUB_FLAGS = "menu.fanclub_flags",
    TEAM_STREAK_TROPHIES = "menu.streak_trophies",
    MATCH_TOP_HATSTATS = "overview.top_matches",
    MATCH_SURPRISING = "overview.surprising_matches",
    MATCH_SPECTATORS = "matches.spectatos",
    OVERVIEW = "menu.team_overview",
    PROMOTIONS = "menu.promotions",
    TEAM_SEARCH = "menu.team_search",
    TEAM_MATCHES = "menu.matches",
    DREAM_TEAM = "menu.dream_team",
    CREATED_SAME_TIME_TEAMS = "menu.created_same_time_teams",
    TEAM_COMPARSION = "menu.comparsion_of_teams",
    OLDEST_TEAMS = "team.date_of_foundation",
    PLAYER_DETAILS = "player.player_details"
}
