export enum SelectorsEnum {
    SEASON_SELECTOR,
    STATS_TYPE_SELECTOR,
    PAGE_SIZE_SELECTOR,
    PAGE_SELECTOR,
    PLAYED_ALL_MATCHES_SELECTOR,
    PLAYER_ROLES,
    NATIONALITIES_SELECTOR,
    AGE_SELECTOR,
    PLAYED_IN_LAST_MATCH_SELECTOR,
    ONE_TEAM_PER_UNIT_SELECTOR,
    EXCLUDE_ZERO_TSI_PLAYERS
}
